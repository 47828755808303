export abstract class Constants {
  static readonly PARAGRAPH_PAGE_BANNER = "paragraph__page_banner";
  static readonly PARAGRAPH_PAGE_INTRO = "paragraph__page_intro";
  static readonly PARAGRAPH_FEATURED_DESTINATIONS =
    "paragraph__featured_destinations";
  static readonly RECENT_SEARCH_KEY = "recentSearchKey";
  static readonly ROOM_AMINITY_ID = "Rooms";
  static readonly SUITE_AMINITY_ID = "Suites";
  static readonly CRS_SUB_SOURCE = "crs_sub_source";
  static readonly BRAND_BIAS_KEY = "brandbiasKey";
  static readonly ERRORSTYPE = {
    RESERVATION: "Reservations Errors",
    TRAVELPASS: "Travel Pass Errors",
    AVAILABILITY: "Hotel Availability API Errors",
    CHECKOUT: "Checkout Page Errors",
  };
  static readonly ERRORSSUBTYPE = {
    TRAVELPASS: {
      SIGNUP: "Sign Up",
      SIGNUPWIFI: "Sign Up WiFi",
      PROFILEACTIVATION: "Activate Profile",
      ACTIVATIONLOGIN: "Login After Activation",
      FORGOTPASSWORD: "Forgot Password",
      RESETPASSWORD: "Reset Password",
      SIGNIN: "Sign In",
      FETCHPROFILE: "Fetch Profile",
      UPDATEPROFILE: "Update Profile",
    },
    RESRVATION: {
      CREATE: "Create Reservation",
      UPDATE: "Update Reservation",
      CANCEL: "Cancel Reservation",
      FETCH: "Fetch Reservation",
    },
    CHECKOUT: {
      HOTELSEARCH: "Hotel Search",
      GUESTDETAILS: "Guest Details",
      CHECKUSEREXIST: "Check User Exists",
    },
  };
  static readonly REGEX = {
    EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  };
}

//Framed Booking
export const Trip_Booking_Token = "TRIP_BOOKING_TOKEN";
export const Trip_Booking_Progress = "TRIP_BOOKING_IN_PROGRESS";
export const Trip_Booking_Success = "TRIP_BOOKING_SUCCESS";
export const Trip_Booking_Failure = "TRIP_BOOKING_FAILURE";
export const Framed_Authentication = "FRAMED_AUTHENTICATION";
export const Edit_Reservation = "EDIT_RESERVATION";
export const Edit_Reservation_Success = "EDIT_RESERVATION_SUCCESS";
export const Recent_Booking_Updated = "RECENT_BOOKING_UPDATED";
export const Trip_Booking_Request_Token = "TRIP_BOOKING_REQUEST_TOKEN";
export const Edit_Reservation_Request_Payload =
  "EDIT_RESERVATION_REQUEST_PAYLOAD";
export const Edit_Reservation_Failure = "EDIT_RESERVATION_FAILURE";
export const Trip_Booking_Login_Failure = "TRIP_BOOKING_LOGIN_FAILURE";

export const Framedview_User_Agent = "framedbooking";

export const GATSBY_MOBILE_APP = process.env.GATSBY_MOBILE_APP === "true";

export const GATSBY_TOKENX_URL =
  process.env.GATSBY_TOKENX_URL ||
  "https://us1-htp.tokenex.com/iframe/iframe-v3.min.js";

export const TP_REDEMPTION_RATE = process.env.GATSBY_TP_REDEMPTION_RATE
  ? process.env.GATSBY_TP_REDEMPTION_RATE.split(",")
  : [];

export const COUNTRY_NAMES: { [Key: string]: string } = {
  US: "United States",
  CA: "Canada",
  AF: "Afghanistan",
  AX: "Åland Islands",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua & Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AC: "Ascension Island",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia",
  BA: "Bosnia & Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  VG: "British Virgin Islands",
  BN: "Brunei",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodia",
  CM: "Cameroon",
  IC: "Canary Islands",
  CV: "Cape Verde",
  BQ: "Caribbean Netherlands",
  KY: "Cayman Islands",
  CF: "Central African Republic",
  EA: "Ceuta & Melilla",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CP: "Clipperton Island",
  CC: "Cocos (Keeling) Islands",
  CO: "Colombia",
  KM: "Comoros",
  CG: "Congo - Brazzaville",
  CD: "Congo - Kinshasa",
  CK: "Cook Islands",
  CR: "Costa Rica",
  CI: "Côte d’Ivoire",
  HR: "Croatia",
  CU: "Cuba",
  CW: "Curaçao",
  CY: "Cyprus",
  CZ: "Czechia",
  DK: "Denmark",
  DG: "Diego Garcia",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  SZ: "Eswatini",
  ET: "Ethiopia",
  FK: "Falkland Islands",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard & McDonald Islands",
  HN: "Honduras",
  HK: "Hong Kong SAR China",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  XK: "Kosovo",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Laos",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao SAR China",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar (Burma)",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  KP: "North Korea",
  MK: "North Macedonia",
  MP: "Northern Mariana Islands",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestinian Territories",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines",
  PN: "Pitcairn Islands",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Romania",
  RU: "Russia",
  RW: "Rwanda",
  WS: "Samoa",
  SM: "San Marino",
  ST: "São Tomé & Príncipe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SX: "Sint Maarten",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia & South Sandwich Islands",
  KR: "South Korea",
  SS: "South Sudan",
  ES: "Spain",
  LK: "Sri Lanka",
  BL: "St. Barthélemy",
  SH: "St. Helena",
  KN: "St. Kitts & Nevis",
  LC: "St. Lucia",
  MF: "St. Martin",
  PM: "St. Pierre & Miquelon",
  VC: "St. Vincent & Grenadines",
  SD: "Sudan",
  SR: "Suriname",
  SJ: "Svalbard & Jan Mayen",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syria",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad & Tobago",
  TA: "Tristan da Cunha",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks & Caicos Islands",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  UY: "Uruguay",
  UM: "US Outlying Islands",
  VI: "US Virgin Islands",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VA: "Vatican City",
  VE: "Venezuela",
  VN: "Vietnam",
  WF: "Wallis & Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};

export const MILES_IN_METERS = 1609.34;

export const IATA_EXPIRE_TIME = 1209600000; // 14 days

export const IATA_MEMBER_ID = "iata-member-ID";

export const IATA_MEMBER_ID_LENGTH = 8;

export const FRAMED_WEB_EXCLUDEED_PATHS = [
  "/contact",
  "/support",
  "/staysafe",
  "/privacy",
  "/terms-use",
  "/edit-reservation",
  "/edit-reservation/select-room",
  "/edit-reservation/select-rate",
  "/edit-reservation/select-payment",
];

export const GATSBY_CVENT_INSTANT_BOOK_URL =
  process.env.GATSBY_CVENT_INSTANT_BOOK_URL ||
  "https://wl-suppliers.app.cvent.com/api/instant-book";

export const RATEPLAN_FILTER_CODE = "rateplanfiltercode";
export const META_RATEPLAN_FILTER_CODE = "meta";

//ABTasty

export const GATSBY_ABTASTY_TEST_ID = process.env.GATSBY_ABTASTY_TEST_ID || "";

export const GATSBY_ABTASTY_VARIATION_ID =
  process.env.GATSBY_ABTASTY_VARIATION_ID || "";
